// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import IncompleteAuthentication from '../components/user/incomplete-authentication';
import crmClientService from '../services/crmClientService';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const Incomplete = ({ location: { state: { userEmail = null, expiredToken = null, context = null } = {} } = {} }) => {
  // console.log('Incomplete', { userEmail, expiredToken, context });

  return (
    <Layout>
      <SEO title="Verification Issue"/>
      <IncompleteAuthentication
        userEmail={userEmail}
        expiredToken={expiredToken}
        context={context}
      />
      <GoogleReCaptcha action="incomplete" onVerify={crmClientService.v3Callback} />
    </Layout>
  );
};

export default Incomplete;
