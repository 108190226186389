import React, { useState } from 'react';
import styles from './incomplete-authentication.module.scss';
import HeadingFullsize from '../common/heading-full-size/full-size-heading';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import pageAliases from '../../data/pageAliases';
import crmService from '../../services/crmClientService';
import locale from '../../locale/locale';
import ButtonLoader from '../common/loaders/button-loader';
import navigate from '../../libs/navigate';

const IncompleteAuthentication = ({ userEmail, expiredToken, context }) => {
  const imgClementinesPlaceholder = useImageStaticQuery('Cannoli.png');
  const backgroundFluidImageStack = {
    lg: imgClementinesPlaceholder.lg.fluid,
    xl: imgClementinesPlaceholder.childImageSharp.fluid,
  };
  const pageData =
    context === 'registration' ? locale.incompleteAuthentication : locale.incompletePasswordReset;
  const [submission, setSubmission] = useState(false);
  const resendValidationEmail = () => {
    setSubmission(true);
    if (userEmail && userEmail !== '') {
      crmService.registrationEmailTokenResend(userEmail).then((result) => {
        navigate(pageAliases.thankyou, { state: { context: context, userEmail: userEmail } });
      });
    } else if (expiredToken) {
      if (context === 'registration') {
        crmService.retrieveNewRegisterConfirmToken(expiredToken).then((result) => {
          navigate(pageAliases.thankyou, { state: { context: context } });
        });
      } else {
        crmService.retrieveNewPasswordResetToken(expiredToken).then((result) => {
          navigate(pageAliases.thankyou, { state: { context: context } });
        });
      }
      setSubmission(false);
    }
  };

  return (
    <div className={styles.registrationConfirmationContainer}>
      <HeadingFullsize
        imageStack={backgroundFluidImageStack}
        title={pageData.pageTitle}
        subTitle={pageData.subTitle}
        description={pageData.description}
        force={true}
      >
        {(userEmail && userEmail !== '') || expiredToken ? (
          <div
            className={styles.incompleteAuthenticationResendButton}
            onClick={() => {
              resendValidationEmail();
            }}
          >
            {pageData.resend}
            {submission && <ButtonLoader />}
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
      </HeadingFullsize>
    </div>
  );
};

export default IncompleteAuthentication;
